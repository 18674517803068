<template>
  <div  class="w-100">
    <div class="menuAutreVin">
    <div v-for="vin in reversedAutre" :key="vin.id">
      <div class="menu-item-autre">
            <h3 class="autreVin">{{ vin.name }}</h3> 
            <span class="menu-item-autre-desc">{{ vin.description }}</span>
            <span class="menu-item-autre-price">{{ vin.price }}€</span>
      </div>
    </div>
  </div>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("couleur.rouge") }} 🍷</h2>
    <v-data-table
      :headers="headers"
      :items="rouge"
      density="compact"
      item-key="name"
      hide-default-footer=""
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="font-weight-bold text-start">
            {{ item.name }}
            <br/>
            <span class="descriptionVin">
              {{ item.description }}
            </span>
          </td>
          <td class="text-end">{{ item.verre ? item.verre+'€':'-' }}</td>
          <td class="text-end">{{ item.demi ? item.demi+'€':'-'}}</td>
          <td class="text-end">{{ item.bouteille ? item.bouteille+'€':'-' }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>

  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("couleur.blanc") }}</h2>
    <v-data-table
      :headers="headers"
      :items="blanc"
      density="compact"
      item-key="name"
      hide-default-footer=""
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="font-weight-bold text-start">
            {{ item.name }}
            <br/>
            <span class="descriptionVin">
              {{ item.description }}
            </span>
          </td>
          <td class="text-end">{{ item.verre ? item.verre+'€':'-' }}</td>
          <td class="text-end">{{ item.demi ? item.demi+'€':'-'}}</td>
          <td class="text-end">{{ item.bouteille ? item.bouteille+'€':'-' }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>

  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("couleur.rosé") }}</h2>
    <v-data-table
      :headers="headers"
      :items="rose"
      density="compact"
      item-key="name"
      hide-default-footer=""
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="font-weight-bold text-start">
            {{ item.name }}
            <br/>
            <span class="descriptionVin">
              {{ item.description }}
            </span>
          </td>
          <td class="text-end">{{ item.verre ? item.verre+'€':'-' }}</td>
          <td class="text-end">{{ item.demi ? item.demi+'€':'-'}}</td>
          <td class="text-end">{{ item.bouteille ? item.bouteille+'€':'-' }}</td>
        </tr>
      </template>
    </v-data-table>
  </div></div>
</template>
  
<script>
import { db } from '@/firebase';
import { onSnapshot, collection } from 'firebase/firestore';

export default {
  name: 'ViniPage',
  data() {
    return {
      digestifs: [],
      rouge: [],
      blanc: [],
      autre: [],
      rose: [],
      headers: [
        { title: '', align: 'start', sortable: false, key: 'name' },
        { title: '14cl', align: 'end', key: 'verre' },
        { title: '37.5cl', align: 'end', key: 'demi' },
        { title: '75cl', align: 'end', key: 'bouteille' },
      ],
      plants: [
        {
          name: 'Fern',
          light: 'Low',
          height: '20cm',
          petFriendly: 'Yes',
          price: 20,
        },
        {
          name: 'Snake Plant',
          light: 'Low',
          height: '50cm',
          petFriendly: 'No',
          price: 35,
        },
        {
          name: 'Monstera',
          light: 'Medium',
          height: '60cm',
          petFriendly: 'No',
          price: 50,
        },
        {
          name: 'Pothos',
          light: 'Low to medium',
          height: '40cm',
          petFriendly: 'Yes',
          price: 25,
        },
        {
          name: 'ZZ Plant',
          light: 'Low to medium',
          height: '90cm',
          petFriendly: 'Yes',
          price: 30,
        },
        {
          name: 'Spider Plant',
          light: 'Bright, indirect',
          height: '30cm',
          petFriendly: 'Yes',
          price: 15,
        },
        {
          name: 'Air Plant',
          light: 'Bright, indirect',
          height: '15cm',
          petFriendly: 'Yes',
          price: 10,
        },
        {
          name: 'Peperomia',
          light: 'Bright, indirect',
          height: '25cm',
          petFriendly: 'Yes',
          price: 20,
        },
        {
          name: 'Aloe Vera',
          light: 'Bright, direct',
          height: '30cm',
          petFriendly: 'Yes',
          price: 15,
        },
        {
          name: 'Jade Plant',
          light: 'Bright, direct',
          height: '40cm',
          petFriendly: 'Yes',
          price: 25,
        },
      ],
    };
  },
  computed: {
    reversedAutre() {
      //return this.autre but with the order reversed
      return this.autre.slice().reverse();
    }
  },
  created() {
    const autreCollection = collection(db, 'vinAutre');
    onSnapshot(autreCollection, snapshot => {
      this.autre = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
    const rougeCollection = collection(db, 'vinRouge');
    onSnapshot(rougeCollection, snapshot => {
      this.rouge = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
    const blancCollection = collection(db, 'vinBlanc');
    onSnapshot(blancCollection, snapshot => {
      this.blanc = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
    const roseCollection = collection(db, 'vinRose');
    onSnapshot(roseCollection, snapshot => {
      this.rose = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
  }
};
</script>
  
<style>
@import url("https://fonts.googleapis.com/css?family=Bree+Serif|Poppins|Source+Sans+Pro|Montserrat:400,900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Poppins", sans-serif;
}
.menu {
  font-family: sans-serif;
  margin-bottom: 10px;
}

.menu-group-heading {
  margin: 0;
  padding-bottom: 1em;
  border-bottom: 2px solid #ccc;
}

.v-data-table-header__content {
    font-weight: bold;
  }

.menu-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
  padding: 1.5em 0;
}

td {
  text-align: justify;
}

.descriptionVin {
  font-size: 10px!important;
  font-weight: normal!important;
}

/* AUTRE VIN */
.menu-item-autre {
  display: flex;
  font-size: 12px;
  padding-left: 28px;
}

.menuAutreVin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-bottom: 10px;
}

.autreVin {
  min-width: 15vw;
  text-align: start;
}

.menu-item-autre-desc {
  margin-right: 40px;
  min-width: 30px;
}

.menu-item-autre-price {
  font-size: 12px;
}
/* ------------ */

@media (max-width: 600px) {
  td {
    font-size: 12px!important;
    text-align: end!important;
    text-wrap: nowrap;
  }
  tr td:nth-child(1) {
    text-align: start;
    padding: 0!important;
  }
  td.text-end {
    padding: 0!important;
    text-align: end!important;
  }
  th {
    padding: 0 9px!important;
  }
  .v-data-table-header__content {
    font-size: 12px!important;
    font-weight: bold;
  }
  th {
    padding: 0!important;
  }

  
/* AUTRE VIN */

.menuAutreVin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-bottom: 10px;
}

.menu-item-autre {
  font-size: 10px;
  padding-left: 16px;
}

.autreVin {
  min-width: 35vw;
  text-align: start;
}

.menu-item-autre-desc {
  margin-right: 40px;
  text-align: end;
}

.menu-item-autre-price {
  font-size: 12px;
}
/* ------------ */
}
</style>