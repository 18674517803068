import { createRouter, createMemoryHistory } from 'vue-router';
import HomePage from '@/components/Home.vue';
import PiattiPage from '@/components/Piatti.vue';
import BevandePage from '@/components/Bevande.vue';
import ViniPage from '@/components/Vini.vue';
import DolciPage from '@/components/Dolci.vue';
import { auth } from '@/firebase';

const routes = [
  { path: '/', component: HomePage },
  { path: '/piatti', component: PiattiPage },
  { path: '/bevande', component: BevandePage },
  { path: '/vini', component: ViniPage },
  { path: '/dolci', component: DolciPage },
];

const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = auth.currentUser;

  if (requiresAuth && !currentUser) {
    next('/login');
  } else {
    next();
  }
});

export default router;
