<template>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("boisson.aperitif") }} 🍸</h2>
    <div class="menu-group">
      <div v-for="aperitif in aperitifs" :key="aperitif.id" class="menu-item">

        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ aperitif.name }}</span>
            <span class="menu-item-price">{{ aperitif.price }}€</span>
          </h3>
          <p class="menu-item-desc">
            {{ aperitif.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("boisson.cocktail") }} 🍹</h2>
    <div class="menu-group">
      <div v-for="cocktail in cocktails" :key="cocktail.id" class="menu-item">

        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ cocktail.name }}</span>
            <span class="menu-item-price">{{ cocktail.price }}€</span>
          </h3>
          <p class="menu-item-desc">
            {{ cocktail.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("boisson.bieres") }} 🍺</h2>
    <div class="menu-group">
      <div v-for="biere in bieres" :key="biere.id" class="menu-item">

        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ biere.name }}</span>
            <span class="menu-item-price">{{ biere.price }}€</span>
          </h3>
          <p class="menu-item-desc">
            {{ biere.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("boisson.softs") }} 🥤</h2>
    <div class="menu-group">
      <div v-for="soft in softs" :key="soft.id" class="menu-item">

        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ soft.name }}</span>
            <span class="menu-item-price">{{ soft.price }}€</span>
          </h3>
          <p class="menu-item-desc">
            {{ soft.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("boisson.chaudes") }} ☕</h2>
    <div class="menu-group">
      <div v-for="cafe in cafes" :key="cafe.id" class="menu-item">

        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ cafe.name }}</span>
            <span class="menu-item-price">{{ cafe.price }}€</span>
          </h3>
          <p class="menu-item-desc">
            {{ cafe.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("boisson.digestif") }} 🍐</h2>
    <div class="menu-group">
      <div v-for="digestif in digestifs" :key="digestif.id" class="menu-item">
        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ digestif.name }}</span>
            <span class="menu-item-price" v-if="digestif.price">{{ digestif.price }}€</span>
          </h3>
          <p class="menu-item-desc">
            {{ digestif.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { db } from '@/firebase';
import { onSnapshot, collection } from 'firebase/firestore';

export default {
  name: 'BevandePage',
  data() {
    return {
      aperitifs: [],
      cocktails: [],
      bieres: [],
      softs: [],
      cafes: [],
      digestifs: [],
    };
  },
  created() {
    const aperitifsCollection = collection(db, 'aperitifs');
    onSnapshot(aperitifsCollection, snapshot => {
      this.aperitifs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
    const cocktailsCollection = collection(db, 'cocktails');
    onSnapshot(cocktailsCollection, snapshot => {
      this.cocktails = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
    const bieresCollection = collection(db, 'bieres');
    onSnapshot(bieresCollection, snapshot => {
      this.bieres = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
    const softsCollection = collection(db, 'softs');
    onSnapshot(softsCollection, snapshot => {
      this.softs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
    const cafesCollection = collection(db, 'cafes');
    onSnapshot(cafesCollection, snapshot => {
      this.cafes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
    const digestifsCollection = collection(db, 'digestifs');
    onSnapshot(digestifsCollection, snapshot => {
      this.digestifs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
  }
};
</script>
  
<style>
@import url("https://fonts.googleapis.com/css?family=Bree+Serif|Poppins|Source+Sans+Pro|Montserrat:400,900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Poppins", sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5em;
}

.menu {
  font-family: sans-serif;
  font-size: 14px;
}

.menu-group-heading {
  margin: 0;
  padding-bottom: 1em;
  border-bottom: 2px solid #ccc;
}

.menu-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
  padding: 1.5em 0;
}

.menu-item {
  display: flex;
}

.menu-item-img {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  margin-right: 1.5em;
}

.menu-item-text {
  flex-grow: 1;
}

.menu-item-heading {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.menu-item-name {
  margin-right: 1.5em;
}

.menu-item-desc {
  text-align: left;
  line-height: 1.6;
}

@media screen and (min-width: 992px) {
  .menu {
    font-size: 16px;
  }

  .menu-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .menu-item-img {
    width: 125px;
    height: 125px;
  }
}
</style>