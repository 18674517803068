import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { auth } from './firebase';
import VueGtm from 'vue-gtm';

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const app = createApp(App);

// i18n
import { createI18n } from 'vue-i18n';
import gb from './locales/gb.json';
import it from './locales/it.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import de from './locales/de.json';
import jp from './locales/jp.json';
import cn from './locales/cn.json';
import pt from './locales/pt.json';
import us from './locales/us.json';
import ru from './locales/ru.json';
import CountryFlag from 'vue-country-flag-next'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdi',
  }
})

const i18n = createI18n({
  locale: navigator.language.split('-')[0] || 'en',
  messages: {
    fr: fr,
    gb: gb,
    it: it,
    es: es,
    de: de,
    jp: jp,
    cn: cn,
    pt: pt,
    us: us,
    ru: ru
  }
})

app.config.productionTip = false;

app.use(VueGtm, {
  id: 'GTM-TWW3J7SX',
  enabled: true,
  debug: true,
  vueRouter: router
});

app.use(router);
app.use(vuetify);
app.use(i18n);
app.component('country-flag', CountryFlag)
auth.onAuthStateChanged(() => {
  app.mount('#app');
});
